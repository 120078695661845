



































































































.q-btn
  width 105px
  & >>> .q-btn__wrapper
    padding 4px 8px
