.text-white {
  color: #fff;
}
.bg-white {
  background: #fff;
}
.nearby {
  z-index: 10;
  padding: 16px;
  border-radius: 16px;
  padding-top: 24px;
  display: grid;
  grid-auto-rows: auto;
  grid-row-gap: 24px;
  align-items: center;
}
.five-grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-row-gap: 16px;
}
.four-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-row-gap: 16px;
}
.three-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-row-gap: 16px;
}
.smartsearch-btn {
  justify-self: center;
  width: 95%;
  background-color: #fff;
  color: #666;
  border: solid #ddd 0.5px;
  padding: 8px 0;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.smartsearch-btn em {
  margin: 0 4px 0 8px;
}
em {
  color: #406e8e;
}
.welcomeBox {
  z-index: -1;
  position: fixed;
  left: 0;
  right: 0;
  top: 64px;
  background-color: #fff;
  padding: 16px 24px;
}
/*# sourceMappingURL=src/pages/home/index.css.map */