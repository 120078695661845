






















































































































.map-search
  margin 0 10px
  margin-top -60px

.search-btn
  top 76px
  background white
  border-radius 25px
  width 80vw
  display inline-block

.back-btn
  top 69px
  border-radius 50%
  margin-right 10px
  width 36px
  height 36px
  background rgba(255, 255, 255, 1)
  position relative
  border 1px solid rgba(0, 0, 0, 0.2)

