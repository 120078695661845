





































































.q-card
  margin 0
  min-height 100vh
