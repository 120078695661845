.ride-hailing-container {
  position: relative;
  overflow: auto;
}
@media (max-width: 768px) {
  .ride-hailing-container .map {
    height: calc(60vh - 60px);
  }
}
.back-btn {
  position: absolute;
  left: 5px;
  top: 10px;
  z-index: 2;
  border-radius: 50%;
  margin-right: 10px;
  width: 36px;
  height: 36px;
  background: #fff;
  border: 1px solid rgba(0,0,0,0.2);
}
/*# sourceMappingURL=src/pages/smart-search/mobile-form/layout.css.map */