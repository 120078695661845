



















































.ride-hailing-container
  position relative
  overflow auto
  .map
   @media (max-width 768px)
    height calc(60vh - 60px)

.back-btn
  position absolute
  left 5px
  top 10px
  z-index 2
  border-radius 50%
  margin-right 10px
  width 36px
  height 36px
  background rgba(255, 255, 255, 1)
  border 1px solid rgba(0, 0, 0, 0.2)
