













































.row
  justify-content space-between
  align-items center
.q-btn
  border-radius 8px
