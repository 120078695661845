.home-details-list {
  padding: 16px 0 60px;
}
.top-box {
  padding: 0 5vw;
}
.button-box {
  display: flex;
  justify-content: space-around;
  padding: 5px 0 10px;
}
.action-button {
  width: 120px;
}
.buy-ticket {
  width: 100%;
}
h6 {
  font-size: 110%;
  margin: 0;
}
.buy-ticket-footer {
  padding: 10px 10px calc(64px + env(safe-area-inset-bottom)) 10px;
  background: #fff;
}
/*# sourceMappingURL=src/pages/home/details/index.css.map */