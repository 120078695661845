










































































































































































































































.shotl-form
  right 35%
  width 30vw
  overflow-y scroll
  z-index 1
  padding 20px 10px 0
  @media (max-width: 768px) {
    width 100vw
    right 0
    left 0
    margin 0
  }

.q-field--with-bottom
  padding-bottom 0

.choose-route
  display grid
  grid-template-columns 36px auto 30px
  grid-column-gap 6px
  grid-template-rows auto
  align-items center

.swap
  grid-column-start 3
  grid-row-start 1
  grid-row-end 3
  color convert(brandColor)
  background white
  border none

.drt-button
  background white
  padding 6px
  border none
  display flex
  flex-direction column
  justify-content center
  align-items center

.confirm
  background convert(brandColor)
  color white
  font-size 20px
  width 100%
  grid-column-start 1
  grid-column-end 4
  border-radius 3px
  margin-bottom 24px
  line-height 1.715em
  padding 4px
  font-weight 500
  height 50px

