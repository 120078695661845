














































.row
  justify-content space-between
  align-items flex-start
.q-btn
  border-radius 8px
.stop-name
  max-width 50%
  display flex
  align-items flex-start
